html{
  font-size: 131.25%;
}

body{
  color: var(--color-black);
  font-family: var(--font-primary);
  line-height: 1.2;
}

h1, h2, h3, h4{
  font-weight: 500;
  line-height: 0.8;
}

strong{
  color: var(--strong-color, inherit);
  font-weight: 600;
  font-style: var(--strong-font-style, normal);
}

.box-content{
  padding-left: 5vw;
  padding-right: 5vw;

  @media (min-width: 960px) {
    padding-left: calc(80px + 5vw);
    padding-right: calc(80px + 5vw);
  }
}

.tag{
  color: var(--color-blue);
  font-size: 0.667rem;
  font-weight: 600;
}

.is-responsive-image{
  height: auto;
  width: 100%;
}

.is-emphasized{
  color: var(--color-red);
  font-style: italic;
}

.is-emphasized--blue{
  color: var(--color-blue);
}