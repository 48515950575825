.button{
  background-color: var(--color-background-button, var(--color-black));
  border: 1px solid var(--color-border-button, var(--color-black));
  border-radius: 4px;
  color: var(--color-button, var(--color-white));
  display: inline-flex;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  padding: 15px 15px 9px 15px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .3s, color .3s;

  &:hover,
  &:focus{
    --color-background-button: var(--color-white);
    --color-button: var(--color-black);
  }
}

.button--outline{
  --color-background-button: var(--color-white);
  --color-button: var(--color-black);
}

.button--outline-red{
  --color-button: var(--color-red);
}