@import "/node_modules/leaflet/dist/leaflet.css";

.month{
  background-color: var(--color-black);
  color: var(--color-white);
  cursor: pointer;
  font-size: 2rem;
  font-style: italic;
  line-height: 1;
  overflow: hidden;
  padding-bottom: 6px;
  padding-top: 15px;
  text-transform: uppercase;
}

.marquee3k__copy{
  margin-right: 9px;
}

.events{
  padding-bottom: 30px;
  padding-top: 30px;
}

.event{
  margin-top: 51px;
}

.event__info{
  display: flex;
  gap: 12px;
  justify-content: center;

  @media (max-width: 959px) {
    border-bottom: 3px solid var(--color-black);
    margin-bottom: 12px;
    padding-bottom: 9px;
  }
}

.event__map{
  border-radius: 6px;
  height: 290px;
  width: 100%;

  @media (max-width: 959px) {
    margin-bottom: 9px;
  }
}

.balloon{
  font-size: 0.7rem;

  p{
    margin: 0;
  }
}

.event__header{
  border-bottom: 3px solid var(--color-black);
  padding-bottom: 9px;

  @media (min-width: 960px) {
    align-items: end;
    display: grid;
    grid-column-gap: 2%;
    grid-template-columns: 30% 70%;
  }
}

.event__speakers{
  color: var(--color-red);
  font-size: 1.333rem;
  font-weight: 500;
  line-height: 1;
}

.event__title{
  font-size: 1.714rem;
  font-style: italic;
  margin-top: 6px;
  text-transform: uppercase;

  @media (min-width: 960px) {
    font-size: 1.952rem;
  }
}

.event__content{
  padding-top: 18px;

  @media (min-width: 960px) {
    display: grid;
    grid-column-gap: 2%;
    grid-template-columns: 30% 70%;
  }
}

.event__text{
  p{
    margin-bottom: 24px;
  }
}

.event__tag{
  display: inline-block;
  margin-bottom: 18px;
}

.event__actions{
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.calendars{
  display: flex;
  gap: 12px;
}

.calendar{
  border: 2px solid var(--color-black);
  border-radius: 8px;
  box-sizing: border-box;
  height: 80px;
  width: 80px;
}

.calendar__day{
  background-color: var(--color-black);
  color: var(--color-white);
  font-size: 0.667rem;
  font-weight: 600;
  padding-bottom: 3px;
  padding-top: 3px;
  text-align: center;
  text-transform: uppercase;
}

.calendar__date{
  font-size: 2.476rem;
  line-height: 1;
  padding-top: 9px;
  text-align: center;
}

.times{
  display: flex;
  gap: 12px;
}

.times__range{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.time{
  border: 2px solid var(--color-black);
  border-radius: 8px;
  box-sizing: border-box;
  display: block;
  font-size: 1.143rem;
  font-weight: 600;
  height: 35px;
  line-height: 1;
  padding-top: 6px;
  text-align: center;
  width: 80px;
}