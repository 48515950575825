.groups{
  margin-bottom: 120px;
}

.group{
  margin-top: 30px;

  &:not(:last-child){
    margin-bottom: 30px;
  }

  &:nth-child(odd){
    .group__title{
      text-align: left;
    }

    .group__date{
      text-align: right;
    }
  }

  @media (min-width: 960px) {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-column-gap: 2%;
    margin-top: 120px;

    &:not(:last-child){
      margin-bottom: 60px;
    }

    &:nth-child(odd){
      grid-template-columns: 60% 40%;

      .group__header{
        grid-column-start: 2;
        grid-column-end: 3;
      }

      .group__slider{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
      }
    }
  }
}

.group__header{
  padding-top: 20%;

  @media (max-width: 959px) {
    margin-bottom: 15px;
  }
}

.group__slider{
  background-color: var(--color-black);
  border-radius: 6px;
  width: 100%;

  @media (max-width: 959px) {
    aspect-ratio: 1 / 1;
  }

  @media (min-width: 960px) {
    height: 450px;
  }
}

.group__title{
  border-bottom: 3px solid var(--color-black);
  font-size: 1.714rem;
  margin-bottom: 6px;
  text-align: right;
  text-transform: uppercase;

  @media (min-width: 960px) {
    font-size: 2.286rem;
  }
}

.group__date{
  display: block;
  font-size: 1.143rem;

  @media (min-width: 960px) {
    font-size: 1.524rem;
  }
}

.group__slider{
  --swiper-pagination-color: var(--color-red);
  --swiper-pagination-bullet-inactive-color: var(--color-white);
  --swiper-pagination-bullet-inactive-opacity: 1;
}
