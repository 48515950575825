.main-logo{
  left: 2%;
  position: fixed;
  top: 2vw;
  z-index: 401;

  @media (max-width: 959px) {
    height: 112px;
    width: 85px;
  }
}

.main-logo__darken{
  mix-blend-mode: darken;
}