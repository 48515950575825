.actors{
  margin-bottom: 51px;
  padding-left: 5vw;

  @media (min-width: 960px) {
    padding-left: calc(80px + 5vw);
  }
}

.actors__title{
  color: var(--color-red);
  font-size: 2.857rem;
  font-weight: 400;
  text-transform: uppercase;
}

.speakers{
  margin-top: 42px;
}

.speaker{
  cursor: pointer;
  width: 320px;

  &:hover,
  &.speaker--active{
    .speaker__media{
      &:before{
        opacity: 1;
      }
    }
  }
}

.speaker__media{
  margin-bottom: 15px;
  position: relative;

  &:before{
    content: '';
    background-image: linear-gradient(180deg, var(--color-red) 0%, var(--color-blue) 100%);
    border-radius: 6px;
    display: block;
    height: 100%;
    mix-blend-mode: color;
    opacity: 0;
    position: absolute;
    transition: opacity .3s;
    width: 100%;
  }
}

.speaker__image{
  border-radius: 6px;
  display: block;
}

.speaker__name{
  font-size: 1.143rem;
  font-style: italic;
}

.speaker__abstract{
  font-size: 0.762rem;
}

.bios{
  color: var(--color-white);
}

.bio{
  --strong-color: var(--color-red);
  --strong-font-style: italic;

  background-color: var(--color-black);
  height: 0;
  overflow: hidden;

  &.bio--active{
    height: auto;
    padding-bottom: 39px;
    padding-top: 39px;
  }
}

.bio__header{
  align-items: baseline;
  display: flex;
  gap: 12px;
  margin-bottom: 39px;
}

.bio__title{
  font-size: 1.619rem;
  font-style: italic;
  line-height: 1;

  @media (min-width: 960px) {
    font-size: 1.952rem;
  }
}

.bio__text{
  margin-bottom: 30px;
}

.bio__list{
  li{
    list-style: none;
    line-height: 1.3;
  }
}