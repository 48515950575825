.sponsors{
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  margin-top: 120px;
  padding-left: 2%;
  padding-right: 2%;
}

.sponsor__title{
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}

.sponsor__logos{
  border-top: 2px solid var(--color-black);
  padding-top: 15px;
}
