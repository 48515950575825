.slider{
  margin-bottom: 30px;
  width: 100%;

  @media (min-width: 960px) {
    height: 85svh;
  }
}

.slider__image{
  object-fit: cover;
  height: auto;
  width: 100%;
}