.accordion__data{
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 500ms;
}

.accordion__data--visible{
  grid-template-rows: 1fr;
}

.accordion__content{
  overflow: hidden;
}
