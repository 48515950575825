.intro{
  padding-left: calc(80px + 5vw);
  padding-right: 5%;

  @media (min-width: 960px) {
    padding-left: calc(200px + 5vw);
  }
}

.intro__content{
  margin-top: 60px;

  @media (min-width: 960px) {
    font-size: 1.524rem;
  }
}

.intro__text{
  &:not(:last-child){
    margin-bottom: 30px;
  }
}