.main-title{
  font-size: 2rem;

  @media (min-width: 960px) {
    font-size: 3rem;
  }
}

.main-subtitle{
  color: var(--color-red);
  font-size: 1.2rem;
  font-style: italic;
  text-transform: uppercase;

  @media (min-width: 960px) {
    font-size: 1.81rem;
  }
}